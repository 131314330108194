.select {
  background: var(--tertiary);
  border: 1px solid var(--aluminum-4);
  color: var(--steel-5);
  font-size: 14px;
  height: var(--input-md);
  padding: var(--xs);
  transition: box-shadow var(--transition-speed-sm) var(--transition-timing);
  word-break: normal;

  
  &.focus,
  &:focus {
    border-color: var(--core-violet-3);
    box-shadow: 0 0 0 3px rgba(0,162,223,.25);
  }
}
