.article {
  padding-bottom: var(--guk-space-5); /* matches nav padding */
  max-width: 900px;
  margin:0 auto; 
  padding: 40px; 

  .listingblock {
    position: relative;

    .btn-copy {
      visibility: hidden;
      position: absolute;
      top: 0;
      right: 0;
      border-radius: var(--guk-border-rounded);
    }

    &:hover .btn-copy {
      visibility: visible;
    }
  }
  
  .listingblock.nopadding {
    pre code {
      padding: 0;
    }

    .btn-copy {
      padding: 0;
    }
  }

  pre code {
    display: block;
    overflow-x: auto;
    padding: var(--guk-space-4);
    border-radius: var(--guk-border-rounded);
    max-width: 90vw;
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  aside {
    background: var(--guk-color-neutral-50);
    border: 1px solid var(--guk-color-neutral-200);
    border-radius: var(--guk-border-rounded);
    color: var(--guk-color-neutral-700);
    font-size: var(--guk-font-size-sm); /* 14px */
    padding: var(--guk-space-3) var(--guk-space-5) var(--guk-space-2);

    &::before {
      background-image: url(../images/icons/note.svg);
      background-position: left 40%;
      background-repeat: no-repeat;
      background-size: 18px;
      color: var(--guk-color-neutral-900);
      content: "Note";
      display: block;
      font-size: var(--guk-font-size-base); /* 16px */
      font-weight: var(--guk-font-weight-semibold);
      opacity: 0.7;
      padding-left: var(--guk-space-5);
    }
  }

  code {
    &.primary {
      color: var(--guk-color-neutral-800);
    }
    &.secondary {
      color: var(--guk-color-primary-400);
    }
    &.light {
      color: var(--guk-color-neutral-700);
    }
  }
}

.heading-link,
.anchor {
  float: right;
  visibility: hidden;
  color: inherit;
  font-size: 0.8em;
  margin-top: calc(var(--guk-space-base) * 0.25); /* Equivalent to 1px */
  margin-left: calc(var(--guk-space-base) * 0.25);

  &:focus,
  h1:hover &,
  h2:hover &,
  h3:hover &,
  h4:hover &,
  h5:hover &,
  h6:hover & {
    visibility: visible;
  }

  .contract & {
    visibility: visible;
  }
}

.anchor {
  padding: 0 calc(var(--guk-space-base) * 0.75); /* Approximately 3px */
  text-decoration: none;

  &::before {
    content: '#';
  }
}

.contract h3 code {
  padding: 0;
  color: inherit;
  background-color: transparent;
}

.contract-index {
  border-radius: var(--guk-border-rounded);
  max-width: 90vw;
  padding: var(--guk-space-6);
  background-color: #f8f8fa;
  margin-top: var(--guk-space-4);
  margin-bottom: var(--guk-space-4);
  word-wrap: break-word;
  color: var(--color-text);

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    a {
      color: inherit;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      pre,
      code {
        padding: 0;
        color: inherit;
        line-height: 1rem;
        background-color: transparent;
      }
    }
  }

  .title {
    font-family: var(--guk-font-family);
    font-size: var(--guk-font-size-sm); /* Approximately 0.9em */
    color: var(--guk-color-primary-400);
    letter-spacing: 0.05em;
    font-weight: var(--guk-font-weight-semibold);
    text-transform: uppercase;
  }

  .ulist {
    border-top: solid 1px var(--guk-color-neutral-300);
    margin-top: var(--guk-space-4);
    padding-top: calc(var(--guk-space-base) * 0.25);

    > .title {
      float: right;
      color: var(--guk-color-neutral-900);
      margin-top: calc(var(--guk-space-base) * 2);
      font-size: var(--guk-font-size-xs);
    }
  }
}

.contract-subindex-inherited {
  opacity: 0.7;
}

.contract-item {
  border-radius: var(--border-radius);
  border: solid 1px #ddd;
  max-width: 90vw;
  padding: 0;
  margin-top: 1em;
  margin-bottom: 1em;
  $spacing: 1em;
  word-wrap: break-word;

  > * {
    margin-right: $spacing;
    margin-left: $spacing;
  }

  h4 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: flex-start;
    padding: 0;
    margin: $spacing;
    margin-bottom: 2 * $spacing;
    position: relative;
    font-size: inherit;

    code {
      flex-grow: 1;
      color: inherit;
      font-weight: var(--weight-normal);
      background-color: transparent;
      padding: 0;

      
      .contract-item-name {
        font-weight: var(--weight-medium);
      }
    }

    .anchor {
      order: 10;
      font-size: var(--md);
      font-weight: normal;
      margin-top: 0;
    }

    .item-kind {
      font-family: var(--sans-serif);
      font-size: var(--md);
      font-weight: 300;
      opacity: .8;
    }

    &::before {
      content: "";
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      box-sizing: content-box;
      padding: $spacing;
      margin: -$spacing;
      z-index: -10;
      border-top-left-radius: var(--border-radius);
      border-top-right-radius: var(--border-radius);
      background-color: #f9f9fa;
      border-bottom: solid 1px #ddd;
    }
  }

  .admonitionblock {
    font-family: var(--sans-serif);
    margin-right: $spacing;
    margin-left: $spacing;
  }
}

.article-navigation {
  display: flex;

  div {
    display: block;
    flex-grow: 1;
    font-weight: var(--guk-font-weight-normal);
    margin-top: var(--guk-space-6);
    margin-bottom: var(--guk-space-2);

    a {
      text-decoration: none;
      font-size: var(--guk-font-size-base);
      color: var(--guk-color-neutral-500);
    }

    a:hover {
      color: var(--guk-color-primary-400);
    }

    &.btn-next {
      text-align: right;
    }
  }
}

.article-banner {
  background-color: var(--guk-color-neutral-100);
  border: solid 1px var(--guk-color-neutral-300);
  border-radius: var(--guk-border-rounded);
  padding: var(--guk-space-2-5);
  margin: 0 calc(-1 * var(--guk-space-2-5));
}

.doc table {
  width: 100%;
  max-width: 100%;
  overflow-x: scroll;
  display: block;
  position: relative;

  &:hover {
    background: var(--guk-color-neutral-50);
  }
}
