/* grid layout */

.card-section .sectionbody, .card-section .content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(calc(max(30%, 230px)), 1fr));;
  grid-gap: 1rem;
}

.card-section-2col .sectionbody, .card-section-2col .content {
  grid-template-columns: repeat(auto-fill, minmax(calc(max(50% - 0.5rem, 250px)), 1fr));
}

.card-section .sectionbody> :not(.card) {
  grid-column: 1 / -1;
}

.card {

  .content,
  .paragraph,
  p {
    display: inline;
  }
}

.card a {
  display: block;
  height: 100%;
  width: 100%;
}

/* inner card layout */

.card a {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem 1.2rem;

  .card-body {
    flex-grow: 1;
  }
}

.card-title {
  display: flex;
  vertical-align: center;
}

.card-body {
  display: block;
}

/* styling */

.card a {
  border-radius: var(--guk-border-rounded);
  color: inherit;
  text-decoration: none;
  font-size: var(--guk-font-size-sm); /* 0.9rem */

  .card-title {
    font-family: var(--guk-font-family);
    font-weight: var(--guk-font-weight-semibold);
    font-size: var(--guk-font-size-base); /* 1rem */
    line-height: 1.4;
    margin-bottom: var(--guk-space-5); /* 1.2rem */
  }
}

/* styling learn */

.card-learn a {
  border: solid 1px var(--guk-color-neutral-300); /* #cacbcc */
  border-left: solid 3px var(--guk-color-neutral-700); /* #53536B */
  color: var(--guk-color-neutral-600); /* Replaces var(--color-text-light) */

  .card-title {
    margin-bottom: var(--guk-space-4); /* 0.8rem */
  }

  &:hover {
    border-color: var(--guk-color-primary-200); /* #B8BFF4 */
    border-left-color: var(--guk-color-primary-400); /* #3164fa */
    box-shadow: 0 6px 12px 0 rgba(60, 60, 60, 0.09);

    .card-title {
      color: var(--guk-color-primary-400); /* #3164fa */
    }
  }
}

/* styling product cards */

.card-primary a,
.card-secondary a {
  box-shadow: 0 5px 10px 0 rgba(60, 60, 60, 0.1);

  &:hover {
    box-shadow: 0 5px 10px 0 rgba(60, 60, 60, 0.3);
  }

  .card-title::before {
    content: "";
    background-image: var(--card-icon);
    background-repeat: no-repeat;
    background-size: contain;
    width: 1.2em;
    height: 1.2em;
    display: inline-block;
    vertical-align: middle;
    margin-right: .6em;
  }
}

.card-primary a {
  min-height: 14rem;
  color: var(--guk-color-neutral-0); /* White text */
  position: relative;
  padding-top: var(--guk-space-12); /* 3rem */
  padding-bottom: var(--guk-space-8); /* 2rem */
  overflow: hidden;

  .card-title {
    font-size: var(--guk-font-size-lg); /* 1.2em */
  }

  &::before {
    display: block;
    width: 95px;
    height: 70px;
    position: absolute;
    bottom: 30px;
    right: -20px;
    content: "";
    background-image: url(../images/cards.svg);
    background-repeat: no-repeat;
    background-size: contain;
  }

  &::after {
    content: "Get started >";
    font-family: var(--guk-font-family);
    font-weight: var(--guk-font-weight-semibold);
    font-size: var(--guk-font-size-sm); /* 0.8em */
    text-transform: uppercase;
    letter-spacing: 0.05em;
  }
}

.card-secondary a {
  border: solid 1px var(--guk-color-neutral-100); /* #e9e9ed */
  color: var(--guk-color-neutral-600); /* Replaces var(--color-text-light) */

  &:hover {
    border-color: var(--guk-color-neutral-200); /* #dfdfe0 */
  }
}

.card-contracts a {
  --card-icon: url(../images/contracts_white.svg);
  background-image: linear-gradient(45deg, var(--guk-color-primary-400) 0%, var(--guk-color-primary-900) 100%);
}

.card-defender a {
  --card-icon: url(../images/defender_white.svg);
  background-image: conic-gradient(45deg, var(--guk-color-primary-400) 0%, var(--guk-color-primary-900) 100%);
}

.card-dao-guide {
  --card-icon: url(../images/icons/osx-contracts.svg);
}

.card-plugin-guide {
  --card-icon: url(../images/icons/multisig.svg);
}

.card-building-app {
  --card-icon: url(../images/icons/gov-ui-kit.svg);
}

.card-spp {
  --card-icon: url(../images/icons/spp.svg);
}

.card-test-environment {
  --card-icon: url(../images/icons/test-environment.svg);
}

.card-network-js {
  --card-icon: url(../images/icons/network-js.svg);
}

.card-gsn-helpers {
  --card-icon: url(../images/icons/gsn-helpers.svg);
}

.card-gsn-provider {
  --card-icon: url(../images/icons/gsn-provider.svg);
}

.card-contract-loader {
  --card-icon: url(../images/icons/contract-loader.svg);
}

.card-solidity-docgen {
  --card-icon: url(../images/icons/docgen.svg);
}

.card-subgraphs {
  --card-icon: url(../images/icons/subgraphs.svg);
}