.toc-sidebar {
  font-size: 0.8em;
  opacity: 0.8;
}

.toc-menu {
  border-width: 0 0 0 0.25rem;

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 1.2;
  }

  .subheading-list {
    border-left: solid 1px var(--guk-color-neutral-200);
    margin-left: var(--guk-space-2); /* Equivalent to 8px */
  }
}

.toc-title {
  font-size: var(--guk-font-size-sm);
  margin-bottom: 16px;
  padding: 0;
  font-family: var(--guk-font-family); /* Replaces var(--heading) */
  color: var(--guk-color-neutral-400); /* Replaces var(--aluminum-5) */
  letter-spacing: 0.05em;
  font-weight: var(--guk-font-weight-normal); /* Assuming bold weight */
  text-transform: uppercase;
}

.toc .toc-menu li {
  margin: 0.5rem 0 0;
  padding-top: var(--guk-space-1); /* 4px */
  padding-bottom: var(--guk-space-1);
  padding-right: var(--guk-space-3); /* 8px */
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: var(--guk-font-weight-normal);
  font-family: var(--guk-font-family); /* Replaces var(--sans-serif) */
  font-size: var(--guk-font-size-base); /* 1rem */

  &.subheading-item {
    font-weight: var(--guk-font-weight-normall); /* Replaces var(--weight-normal) */
   /* margin: 0.25rem 0 0; */
    margin-left: .7rem; 
  }
}

.toc a {
  color: inherit;
  text-decoration: none;
  padding-right: 8px;

  &:hover {
    color: var(--guk-color-primary-300); /* Replaces var(--core-violet-2) */
  }
}

.toc .toc-menu li a.toc-active {
  color: var(--guk-color-primary-400); /* Replaces var(--core-violet-4) */
}
