.input,
.textarea {
  background: var(--tertiary);
  border: 1px solid var(--aluminum-4);
  border-radius: 2px;
  color: var(--steel-5);
  font-size: 14px;
  height: var(--input-md);
  padding: var(--xs);
  transition: box-shadow var(--transition-speed-sm) var(--transition-timing);
  width: 100%;
  word-break: normal;

  
  &.focus,
  &:focus {
    border-color: var(--core-violet-3);
    box-shadow: 0 0 0 3px rgba(0,162,223,.25);
  }

  
  &::placeholder {
    color: var(--aluminum-5);
  }
}

.textarea {
  resize: vertical;
}
