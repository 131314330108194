/* version-select.scss */

.component-version {
  position: relative;
}

.btn-version {
  font-size: 0.85rem;
  font-weight: var(--guk-font-weight-normal); /* Replaces var(--weight-normal) */
  min-height: 1.5rem;
  padding: 0 var(--guk-space-2); /* 8px */
  margin: 0.4rem 0;
  border: 1px solid;
  border-radius: var(--guk-border-rounded-full); /* 9999px */

  &::after {
    display: inline-block;
    content: url(../images/icons/chevron.svg);
    width: 0.8rem;
    margin-left: 0.2rem;
  }

  &:hover,
  &:focus {
    color: var(--guk-color-primary-400); /* Replaces var(--core-violet-4) */
  }

  svg {
    height: var(--guk-space-4); /* Replaces var(--md) */
    width: var(--guk-space-4);
  }
}

.popover-container {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 1;
  padding-top: 0.5em;

  .btn-version:focus + &,
  &:focus-within {
    opacity: 1;
    pointer-events: auto;
  }
}

.popover {
  font-family: var(--guk-font-family); /* Replaces var(--sans-serif) */
  font-size: 0.9em;
  background: var(--guk-color-neutral-0); /* Replaces var(--tertiary) */
  border: 1px solid var(--guk-color-neutral-100); /* Replaces var(--aluminum-3) */
  border-radius: var(--guk-border-rounded); /* Replaces var(--border-radius) */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  color: var(--guk-color-neutral-700); /* Replaces var(--steel-3) */
  padding: 0.5em;
  margin: 0 var(--guk-space-1); /* Replaces var(--xs) */
  width: 10em;
  user-select: none;

  p {
    margin: 0;
    padding: 0 0.5em;
    font-weight: bold;
    font-size: 0.9em;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;

      a {
        display: block;
        padding: 0.2em 0.5em;

        &:hover {
          background-color: var(--guk-color-neutral-50); /* Replaces var(--aluminum-2) */
        }
      }
    }
  }
}
