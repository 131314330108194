svg {
  fill: currentColor;
}

svg.icon {
  height: 1em;
  width: 1em;
  pointer-events: none;
  vertical-align: -.1em;
}

.btn-icon {
  border: 0;
  display: flex;
  padding: .5em;
}
