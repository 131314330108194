.hardhat-truffle-toggle {
  width: 120px;
  height: 30px;
  border: 0;
  outline: 0;
  padding: 0;
  margin: 0;
  background: url(../images/toggle-active.svg) center / contain no-repeat;
  overflow: hidden;
  white-space: nowrap;

  &:active {
    background-image: url(../images/toggle-active.svg) !important;
  }

  span {
    pointer-events: none;
    opacity: 0;
  }
}

.preference-truffle {
  .hardhat {
    display: none;
  }

  .hardhat-truffle-toggle {
    background-image: url(../images/toggle-preference-truffle.svg);
  }
}

.preference-hardhat {
  .truffle {
    display: none;
  }

  .hardhat-truffle-toggle {
    background-image: url(../images/toggle-preference-hardhat.svg);
  }
}
