html {
  -ms-text-size-adjust: 100;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

details,
main,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  vertical-align: baseline;
}

[hidden],
template {
  display: none;
}

a {
  background: transparent;
}
a:hover, a:active {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure,
blockquote {
  margin: 0;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  display: block;
  line-height: 1.4;
  margin: 0;
  overflow: auto;
  word-break: break-all;
  word-wrap: break-word;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  background: transparent;
  box-shadow: none;
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  height: auto;
}

input,
textarea {
  -webkit-appearance: none;
  background: transparent;
  border: 0;
}
input[type=radio],
textarea[type=radio] {
  -webkit-appearance: radio;
  cursor: pointer;
}

fieldset {
  border: 1px solid #cbd2d9;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

button,
input,
select[multiple],
textarea {
  background-image: none;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

ol,
ul {
  /* list-style-type: none; */
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

iframe {
  border: 0;
}

address {
  font-style: normal;
}

input[disabled] textarea[disabled] {
  cursor: not-allowed;
}

input[type=search]::-webkit-search-decoration, input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-results-button, input[type=search]::-webkit-search-results-decoration {
  display: none;
}

:root {
  --core-gray: #f8f8fa;
  --core-violet-1: #9aa2e7;
  --core-violet-2: #6d79e9;
  --core-violet-3: #6472e7;
  --core-violet-4: #4e5ee4;
  --core-violet-6: #282846;
  --robust-blue-1: #a1b1b8;
  --robust-blue-2: #6b8a99;
  --robust-blue-3: #506773;
  --robust-blue-4: #32444d;
  --robust-blue-5: #272f33;
  --future-green-1: #aaf2cb;
  --future-green-2: #33cc7a;
  --future-green-3: #17bc65;
  --future-green-4: #0e8c48;
  --future-green-5: #174d30;
  --steel-1: #6b6c6d;
  --steel-2: #58595a;
  --steel-3: #3a3b3c;
  --steel-4: #3D3D58;
  --steel-5: #121314;
  --aluminum-0: #f5f6fe;
  --aluminum-1: #f9fafb;
  --aluminum-2: #f4f5f6;
  --aluminum-3: #e8e9ea;
  --aluminum-4: #cacbcc;
  --aluminum-5: #989a9b;
  --yellow-3: #f2be24;
  --viridian-3: #00b49d;
  --teal-3: #00b5d1;
  --navy-3: #178bea;
  --indigo-3: #5e66f9;
  --violet-3: #9a63f9;
  --red-3: #d1344e;
  --blue: #196BF7;
  --tertiary: #fff;
  --color-text: #282846;
  --color-text-light: #555566;
  --color-navbar-text: #282846;
  --border-radius: 0.3rem;
  --heading: "Manrope", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  --sans-serif: "Manrope", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
  --monospace: "Roboto Mono", monospace;
  --weight-normal: 400;
  --weight-medium: 500;
  --weight-bold: 600;
  --header-height: 66px;
  --navbar-height: 70px;
  --footer-height: 361px;
  --nav-item-height: 32px;
  --xs: 5px;
  --sm: 10px;
  --md: 15px;
  --lg: 20px;
  --xl: 40px;
  --input-md: 30px;
  --transition-speed-sm: 0.15s;
  --transition-speed-md: 0.3s;
  --transition-timing: cubic-bezier(0.46, 0.03, 0.52, 0.96);
  /* Border Radius */
  --guk-border-rounded: 4px;
  --guk-border-rounded-lg: 8px;
  --guk-border-rounded-xl: 12px;
  --guk-border-rounded-2xl: 16px;
  --guk-border-rounded-3xl: 24px;
  --guk-border-rounded-full: 9999px;
  --guk-border-rounded-none: 0;
  /* Colors */
  /* Primary */
  --guk-color-primary-50: #f5f8ff;
  --guk-color-primary-100: #c4d7ff;
  --guk-color-primary-200: #93b2ff;
  --guk-color-primary-300: #628cfe;
  --guk-color-primary-400: #3164fa;
  --guk-color-primary-500: #003bf5;
  --guk-color-primary-600: #0037d2;
  --guk-color-primary-700: #0031ad;
  --guk-color-primary-800: #002985;
  --guk-color-primary-900: #001f5c;
  /* Neutral */
  --guk-color-neutral-0: #ffffff;
  --guk-color-neutral-50: #f5f7fa;
  --guk-color-neutral-100: #e4e7eb;
  --guk-color-neutral-200: #cbd2d9;
  --guk-color-neutral-300: #9aa5b1;
  --guk-color-neutral-400: #7b8794;
  --guk-color-neutral-500: #616e7c;
  --guk-color-neutral-600: #52606d;
  --guk-color-neutral-700: #3e4c59;
  --guk-color-neutral-800: #323f4b;
  --guk-color-neutral-900: #1f2933;
  /* Info */
  --guk-color-info-100: #d1fdfa;
  --guk-color-info-200: #a4f9fb;
  --guk-color-info-300: #75e8f3;
  --guk-color-info-400: #52cfe7;
  --guk-color-info-500: #1eadd8;
  --guk-color-info-600: #1588b9;
  --guk-color-info-700: #0f669b;
  --guk-color-info-800: #09497d;
  --guk-color-info-900: #053467;
  /* Success */
  --guk-color-success-100: #f3fccc;
  --guk-color-success-200: #e4f99a;
  --guk-color-success-300: #ccef66;
  --guk-color-success-400: #b2e040;
  --guk-color-success-500: #8ecc0a;
  --guk-color-success-600: #74af07;
  --guk-color-success-700: #5c9205;
  --guk-color-success-800: #467603;
  --guk-color-success-900: #366101;
  /* Warning */
  --guk-color-warning-100: #fff3d6;
  --guk-color-warning-200: #ffe4ad;
  --guk-color-warning-300: #ffd083;
  --guk-color-warning-400: #ffbe65;
  --guk-color-warning-500: #ff9f32;
  --guk-color-warning-600: #db7d24;
  --guk-color-warning-700: #b75e19;
  --guk-color-warning-800: #93430f;
  --guk-color-warning-900: #7a3009;
  /* Critical */
  --guk-color-critical-100: #fee4d6;
  --guk-color-critical-200: #fec3ae;
  --guk-color-critical-300: #fd9a86;
  --guk-color-critical-400: #fb7467;
  --guk-color-critical-500: #f93636;
  --guk-color-critical-600: #d62736;
  --guk-color-critical-700: #b31b35;
  --guk-color-critical-800: #901132;
  --guk-color-critical-900: #770a30;
  /* Transparent */
  --guk-color-transparent: transparent;
  /* Spacing */
  --guk-space-base: 4px;
  --guk-space-0: calc(var(--guk-space-base) * 0);
  --guk-space-0-25: calc(var(--guk-space-base) * 0.25);
  --guk-space-0-5: calc(var(--guk-space-base) * 0.5);
  --guk-space-1-5: calc(var(--guk-space-base) * 1.5);
  --guk-space-2: calc(var(--guk-space-base) * 2);
  --guk-space-2-5: calc(var(--guk-space-base) * 2.5);
  --guk-space-3: calc(var(--guk-space-base) * 3);
  --guk-space-3-5: calc(var(--guk-space-base) * 3.5);
  --guk-space-4: calc(var(--guk-space-base) * 4);
  --guk-space-5: calc(var(--guk-space-base) * 5);
  --guk-space-6: calc(var(--guk-space-base) * 6);
  --guk-space-7: calc(var(--guk-space-base) * 7);
  --guk-space-8: calc(var(--guk-space-base) * 8);
  --guk-space-9: calc(var(--guk-space-base) * 9);
  --guk-space-10: calc(var(--guk-space-base) * 10);
  --guk-space-11: calc(var(--guk-space-base) * 11);
  --guk-space-12: calc(var(--guk-space-base) * 12);
  --guk-space-14: calc(var(--guk-space-base) * 14);
  --guk-space-16: calc(var(--guk-space-base) * 16);
  --guk-space-20: calc(var(--guk-space-base) * 20);
  --guk-space-24: calc(var(--guk-space-base) * 24);
  --guk-space-28: calc(var(--guk-space-base) * 28);
  --guk-space-32: calc(var(--guk-space-base) * 32);
  --guk-space-36: calc(var(--guk-space-base) * 36);
  --guk-space-40: calc(var(--guk-space-base) * 40);
  --guk-space-44: calc(var(--guk-space-base) * 44);
  --guk-space-48: calc(var(--guk-space-base) * 48);
  --guk-space-52: calc(var(--guk-space-base) * 52);
  --guk-space-56: calc(var(--guk-space-base) * 56);
  --guk-space-60: calc(var(--guk-space-base) * 60);
  --guk-space-64: calc(var(--guk-space-base) * 64);
  --guk-space-72: calc(var(--guk-space-base) * 72);
  --guk-space-80: calc(var(--guk-space-base) * 80);
  --guk-space-96: calc(var(--guk-space-base) * 96);
  /* Typography */
  /* Font Weight */
  --guk-font-weight-normal: 500;
  --guk-font-weight-semibold: 600;
  /* Font Size */
  --guk-font-size-xs: 0.75rem;
  --guk-font-size-sm: 0.875rem;
  --guk-font-size-base: 1rem;
  --guk-font-size-lg: 1.125rem;
  --guk-font-size-xl: 1.25rem;
  --guk-font-size-2xl: 1.5rem;
  --guk-font-size-3xl: 1.875rem;
  --guk-font-size-4xl: 2.25rem;
  --guk-font-size-5xl: 3rem;
  /* Line Height */
  --guk-line-height-normal: 1.5;
  --guk-line-height-tight: 1.25;
  --guk-line-height-relaxed: 1.65;
  /* Font Family */
  --guk-font-family: "Manrope", ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
    "Noto Color Emoji";
}

/* Font-face Definitions */
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../../fonts/Manrope-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Manrope";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../../fonts/Manrope-SemiBold.ttf") format("truetype");
}
body {
  background: var(--guk-color-neutral-0);
}

.filter-active-icon {
  filter: invert(49%) sepia(69%) saturate(6505%) hue-rotate(225deg) brightness(93%) contrast(91%);
}

@media (min-width: 1160px) {
  .container {
    max-width: 1160px;
  }
}

.flex {
  display: flex;
}

.col {
  flex-direction: column;
}

.align-top {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-bottom {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-justified {
  justify-content: space-between;
}

.grow {
  flex: 1 1 auto;
}

.shrink {
  flex: 0 0 auto;
}

/* typography.scss */
/* Font-face Definitions */
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope-Regular.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
/* Body Styles */
body {
  color: var(--guk-color-neutral-900);
  font-family: var(--guk-font-family);
  font-size: var(--guk-font-size-base); /* 1rem */
  line-height: var(--guk-line-height-normal); /* 1.5 */
}

/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--guk-color-neutral-900);
  font-family: var(--guk-font-family);
  /* font-weight: var(--guk-font-weight-semibold); /* 600 */
  line-height: var(--guk-line-height-tight); /* 1.25 */
  margin-bottom: var(--guk-space-4);
  margin-top: var(--guk-space-7);
}

/*
h2,
h3,
h4,
h5,
h6 {
  padding-top: var(--guk-space-8); 

}
*/
h1 {
  /* padding-top: var(--guk-space-6); /* Adjusted spacing */
  border-bottom: 1px solid var(--guk-color-neutral-200);
}

/* Heading Specific Styles */
h1 {
  font-size: var(--guk-font-size-3xl); /* 3rem */
  padding-bottom: var(--guk-space-2);
}

h2 {
  font-size: var(--guk-font-size-2xl); /* 2.25rem */
}

h3 {
  font-size: var(--guk-font-size-xl); /* 1.875rem */
}

h4 {
  font-size: var(--guk-font-size-lg); /* 1.5rem */
}

h5 {
  font-size: var(--guk-font-size-m); /* 1.25rem */
}

h6 {
  font-size: var(--guk-font-size-m); /* 1.125rem */
}

/* Inline Code */
code {
  background-color: var(--guk-color-neutral-50); /* #f5f7fa */
  border-radius: var(--guk-border-rounded); /* 4px */
  font-family: var(--guk-font-family-monospace, monospace);
  font-size: 0.95em;
  padding: 0;
  border: 1px solid var(--guk-color-neutral-200);
}

/* Paragraphs and Lists */
p,
figure {
  margin-bottom: var(--guk-space-4);
  /* margin-top: var(--guk-space-6); */
}

ul,
ol,
dl {
  margin-bottom: var(--guk-space-4);
  margin-top: var(--guk-space-4);
  padding-left: var(--guk-space-6);
}

li {
  margin-top: var(--guk-space-2);
  margin-bottom: var(--guk-space-2);
}

ol {
  list-style-type: decimal;
}

ul {
  list-style-type: disc;
}

/* Links */
a {
  color: var(--guk-color-primary-500);
  text-decoration: none;
  transition: color var(--transition-speed-md) var(--transition-timing);
}
a:hover {
  color: var(--guk-color-primary-400);
}

/* Preformatted Text */
pre,
code {
  font-family: var(--guk-font-family-monospace, monospace);
  font-size: 0.95em;
}

pre {
  background-color: var(--guk-color-neutral-0);
  border-radius: var(--guk-border-rounded);
  /*  padding: var(--guk-space-4); */
  overflow: auto;
  margin-bottom: var(--guk-space-6);
  margin-top: var(--guk-space-6);
}

/* Table Headings */
thead {
  font-family: var(--guk-font-family);
  font-variant: small-caps;
  text-transform: lowercase;
  letter-spacing: 0.05em;
  color: var(--guk-color-neutral-500);
  border-bottom: 1px solid var(--guk-color-neutral-200);
}

th {
  text-align: left;
  padding: var(--guk-space-2);
}

td,
th {
  padding: var(--guk-space-2);
}

td > .tableblock {
  margin: 0;
}

/* Definition Lists */
dl {
  border: 1px solid var(--guk-color-neutral-200);
  border-radius: var(--guk-border-rounded);
  display: inline-block;
  padding: var(--guk-space-4);
}

dt {
  font-weight: var(--guk-font-weight-semibold);
}
dt::after {
  content: ":";
}

dd {
  margin-left: 0;
}
dd + dt {
  border-top: 1px solid var(--guk-color-neutral-200);
  margin-top: var(--guk-space-2);
  padding-top: var(--guk-space-2);
}

/* Blockquotes */
blockquote {
  margin-left: var(--guk-space-8);
  border-left: 4px solid var(--guk-color-neutral-200);
  padding-left: var(--guk-space-4);
  color: var(--guk-color-neutral-700);
  font-style: italic;
}

blockquote + .attribution {
  text-align: right;
  font-size: var(--guk-font-size-sm);
  color: var(--guk-color-neutral-600);
}

/* Strong Text */
strong,
.strong {
  font-weight: var(--guk-font-weight-semibold);
}

/* Strikethrough Text */
.strikethrough {
  text-decoration: line-through;
}

/* Miscellaneous */
pre,
table,
aside {
  margin-bottom: var(--guk-space-6);
  margin-top: var(--guk-space-6);
}

/* Variables Fallbacks */
:root {
  --guk-font-family-monospace: "Roboto Mono", monospace;
}

.info-text-style {
  margin-top: var(--guk-space-14);
}

svg {
  fill: currentColor;
}

svg.icon {
  height: 1em;
  width: 1em;
  pointer-events: none;
  vertical-align: -0.1em;
}

.btn-icon {
  border: 0;
  display: flex;
  padding: 0.5em;
}

.input,
.textarea {
  background: var(--tertiary);
  border: 1px solid var(--aluminum-4);
  border-radius: 2px;
  color: var(--steel-5);
  font-size: 14px;
  height: var(--input-md);
  padding: var(--xs);
  transition: box-shadow var(--transition-speed-sm) var(--transition-timing);
  width: 100%;
  word-break: normal;
}
.input.focus, .input:focus,
.textarea.focus,
.textarea:focus {
  border-color: var(--core-violet-3);
  box-shadow: 0 0 0 3px rgba(0, 162, 223, 0.25);
}
.input::placeholder,
.textarea::placeholder {
  color: var(--aluminum-5);
}

.textarea {
  resize: vertical;
}

.select {
  background: var(--tertiary);
  border: 1px solid var(--aluminum-4);
  color: var(--steel-5);
  font-size: 14px;
  height: var(--input-md);
  padding: var(--xs);
  transition: box-shadow var(--transition-speed-sm) var(--transition-timing);
  word-break: normal;
}
.select.focus, .select:focus {
  border-color: var(--core-violet-3);
  box-shadow: 0 0 0 3px rgba(0, 162, 223, 0.25);
}

/* grid layout */
.card-section .sectionbody, .card-section .content {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max(30%, 230px), 1fr));
  grid-gap: 1rem;
}

.card-section-2col .sectionbody, .card-section-2col .content {
  grid-template-columns: repeat(auto-fill, minmax(max(50% - 0.5rem, 250px), 1fr));
}

.card-section .sectionbody > :not(.card) {
  grid-column: 1/-1;
}

.card .content,
.card .paragraph,
.card p {
  display: inline;
}

.card a {
  display: block;
  height: 100%;
  width: 100%;
}

/* inner card layout */
.card a {
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1.5rem 1.2rem;
}
.card a .card-body {
  flex-grow: 1;
}

.card-title {
  display: flex;
  vertical-align: center;
}

.card-body {
  display: block;
}

/* styling */
.card a {
  border-radius: var(--guk-border-rounded);
  color: inherit;
  text-decoration: none;
  font-size: var(--guk-font-size-sm); /* 0.9rem */
}
.card a .card-title {
  font-family: var(--guk-font-family);
  font-weight: var(--guk-font-weight-semibold);
  font-size: var(--guk-font-size-base); /* 1rem */
  line-height: 1.4;
  margin-bottom: var(--guk-space-5); /* 1.2rem */
}

/* styling learn */
.card-learn a {
  border: solid 1px var(--guk-color-neutral-300); /* #cacbcc */
  border-left: solid 3px var(--guk-color-neutral-700); /* #53536B */
  color: var(--guk-color-neutral-600); /* Replaces var(--color-text-light) */
}
.card-learn a .card-title {
  margin-bottom: var(--guk-space-4); /* 0.8rem */
}
.card-learn a:hover {
  border-color: var(--guk-color-primary-200); /* #B8BFF4 */
  border-left-color: var(--guk-color-primary-400); /* #3164fa */
  box-shadow: 0 6px 12px 0 rgba(60, 60, 60, 0.09);
}
.card-learn a:hover .card-title {
  color: var(--guk-color-primary-400); /* #3164fa */
}

/* styling product cards */
.card-primary a,
.card-secondary a {
  box-shadow: 0 5px 10px 0 rgba(60, 60, 60, 0.1);
}
.card-primary a:hover,
.card-secondary a:hover {
  box-shadow: 0 5px 10px 0 rgba(60, 60, 60, 0.3);
}
.card-primary a .card-title::before,
.card-secondary a .card-title::before {
  content: "";
  background-image: var(--card-icon);
  background-repeat: no-repeat;
  background-size: contain;
  width: 1.2em;
  height: 1.2em;
  display: inline-block;
  vertical-align: middle;
  margin-right: 0.6em;
}

.card-primary a {
  min-height: 14rem;
  color: var(--guk-color-neutral-0); /* White text */
  position: relative;
  padding-top: var(--guk-space-12); /* 3rem */
  padding-bottom: var(--guk-space-8); /* 2rem */
  overflow: hidden;
}
.card-primary a .card-title {
  font-size: var(--guk-font-size-lg); /* 1.2em */
}
.card-primary a::before {
  display: block;
  width: 95px;
  height: 70px;
  position: absolute;
  bottom: 30px;
  right: -20px;
  content: "";
  background-image: url(../images/cards.svg);
  background-repeat: no-repeat;
  background-size: contain;
}
.card-primary a::after {
  content: "Get started >";
  font-family: var(--guk-font-family);
  font-weight: var(--guk-font-weight-semibold);
  font-size: var(--guk-font-size-sm); /* 0.8em */
  text-transform: uppercase;
  letter-spacing: 0.05em;
}

.card-secondary a {
  border: solid 1px var(--guk-color-neutral-100); /* #e9e9ed */
  color: var(--guk-color-neutral-600); /* Replaces var(--color-text-light) */
}
.card-secondary a:hover {
  border-color: var(--guk-color-neutral-200); /* #dfdfe0 */
}

.card-contracts a {
  --card-icon: url(../images/contracts_white.svg);
  background-image: linear-gradient(45deg, var(--guk-color-primary-400) 0%, var(--guk-color-primary-900) 100%);
}

.card-defender a {
  --card-icon: url(../images/defender_white.svg);
  background-image: conic-gradient(45deg, var(--guk-color-primary-400) 0%, var(--guk-color-primary-900) 100%);
}

.card-dao-guide {
  --card-icon: url(../images/icons/osx-contracts.svg);
}

.card-plugin-guide {
  --card-icon: url(../images/icons/multisig.svg);
}

.card-building-app {
  --card-icon: url(../images/icons/gov-ui-kit.svg);
}

.card-spp {
  --card-icon: url(../images/icons/spp.svg);
}

.card-test-environment {
  --card-icon: url(../images/icons/test-environment.svg);
}

.card-network-js {
  --card-icon: url(../images/icons/network-js.svg);
}

.card-gsn-helpers {
  --card-icon: url(../images/icons/gsn-helpers.svg);
}

.card-gsn-provider {
  --card-icon: url(../images/icons/gsn-provider.svg);
}

.card-contract-loader {
  --card-icon: url(../images/icons/contract-loader.svg);
}

.card-solidity-docgen {
  --card-icon: url(../images/icons/docgen.svg);
}

.card-subgraphs {
  --card-icon: url(../images/icons/subgraphs.svg);
}

/* navigation.scss */
.nav {
  border-right: solid 1px var(--guk-color-neutral-100);
  padding: 1rem;
  background: var(--guk-color-neutral-0); /* Replaces var(--core-gray) */
  color: var(--guk-color-neutral-600); /* Replaces var(--color-text-light) */
}
.nav a {
  text-decoration: none;
  /*  color: var(--guk-color-neutral-600); */
}
.nav .nav-li-active > a {
  color: var(--guk-color-primary-400); /* Replaces var(--core-violet-4) */
  /*  border-bottom: 2px solid var(--guk-color-primary-400); */
}

.nav-component {
  line-height: 1rem;
}

.nav-component a {
  padding: 0.7rem 1rem;
}

.nav-title {
  padding: 0 1rem;
  font-size: 0.8em;
  font-family: var(--guk-font-family); /* Replaces var(--heading) */
  color: var(--guk-color-neutral-300); /* Replaces var(--aluminum-5) */
  letter-spacing: 0.05em;
  font-weight: var(--guk-font-weight-semibold); /* Replaces var(--weight-bold) */
  text-transform: uppercase;
  margin-top: var(--guk-space-5);
}

.btn-back {
  /* opacity: 0.6; 
   margin-bottom: 0.5rem;
   line-height: 1.5rem;*/
  margin-left: 1.3rem;
  cursor: pointer;
  color: var(--guk-color-neutral-400);
}
.btn-back::before {
  display: inline-block;
  content: url(../images/icons/arrow_back.svg);
  margin-right: 0.3em;
  margin-left: -1.3rem;
  width: 1rem;
}

.btn-back:hover {
  color: var(--guk-color-neutral-700);
}

.nav-heading {
  font-size: 1rem;
  font-weight: var(--guk-font-weight-semibold); /* Replaces var(--weight-medium) */
  font-family: var(--guk-font-family); /* Replaces var(--heading) */
  /* padding: 0 1rem; */
}

.nav-component-active .nav-heading-active {
  padding-top: 15px;
  padding-bottom: 4px;
  background: var(--guk-color-neutral-50); /* Replaces var(--core-gray) */
  z-index: 2;
}

.nav-component-heading {
  color: var(--guk-color-neutral-600); /* Replaces var(--steel-4) */
  font-family: var(--guk-font-family); /* Replaces var(--heading) */
  font-weight: var(--guk-font-weight-normal); /* Replaces var(--weight-medium) */
  position: relative;
  font-size: 1rem;
}

.nav-link {
  color: var(--guk-color-neutral-600);
  margin-bottom: var(--guk-space-2);
  cursor: pointer;
}

.nav-link:hover {
  color: var(--guk-color-neutral-900);
  cursor: pointer;
}

.nav-link code {
  font-size: 0.9rem;
  padding: 0;
}

.nav-icon {
  opacity: 0.8;
}

button.collapse-toggle {
  cursor: pointer;
  padding: 0;
  border: none;
}
button.collapse-toggle.toggled img {
  transform: rotate(-90deg);
}
button.collapse-toggle.toggled ~ .collapsible-content {
  display: none;
}

.close-menu-btn {
  border: 0;
}

/* Scope list styles to navigation */
.nav-list {
  list-style: none;
  padding-left: 0;
  margin: 0; /* Optional: remove default margins */
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-color: var(--guk-color-neutral-100) transparent;
  scrollbar-width: thin;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--guk-color-neutral-100);
  border-radius: 10px;
}

/* version-select.scss */
.component-version {
  position: relative;
}

.btn-version {
  font-size: 0.85rem;
  font-weight: var(--guk-font-weight-normal); /* Replaces var(--weight-normal) */
  min-height: 1.5rem;
  padding: 0 var(--guk-space-2); /* 8px */
  margin: 0.4rem 0;
  border: 1px solid;
  border-radius: var(--guk-border-rounded-full); /* 9999px */
}
.btn-version::after {
  display: inline-block;
  content: url(../images/icons/chevron.svg);
  width: 0.8rem;
  margin-left: 0.2rem;
}
.btn-version:hover, .btn-version:focus {
  color: var(--guk-color-primary-400); /* Replaces var(--core-violet-4) */
}
.btn-version svg {
  height: var(--guk-space-4); /* Replaces var(--md) */
  width: var(--guk-space-4);
}

.popover-container {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 1;
  padding-top: 0.5em;
}
.btn-version:focus + .popover-container, .popover-container:focus-within {
  opacity: 1;
  pointer-events: auto;
}

.popover {
  font-family: var(--guk-font-family); /* Replaces var(--sans-serif) */
  font-size: 0.9em;
  background: var(--guk-color-neutral-0); /* Replaces var(--tertiary) */
  border: 1px solid var(--guk-color-neutral-100); /* Replaces var(--aluminum-3) */
  border-radius: var(--guk-border-rounded); /* Replaces var(--border-radius) */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  color: var(--guk-color-neutral-700); /* Replaces var(--steel-3) */
  padding: 0.5em;
  margin: 0 var(--guk-space-1); /* Replaces var(--xs) */
  width: 10em;
  user-select: none;
}
.popover p {
  margin: 0;
  padding: 0 0.5em;
  font-weight: bold;
  font-size: 0.9em;
}
.popover ul {
  margin: 0;
  padding: 0;
}
.popover ul li {
  list-style: none;
}
.popover ul li a {
  display: block;
  padding: 0.2em 0.5em;
}
.popover ul li a:hover {
  background-color: var(--guk-color-neutral-50); /* Replaces var(--aluminum-2) */
}

/* These are hidden from the site and only shown in the READMEs on GitHub. */
.readme-notice {
  display: none;
}

.root {
  display: flex;
}

.nav {
  height: 100vh;
  overflow-y: auto;
  position: sticky;
  top: 0;
  flex: 0 0 auto;
  width: 300px;
}
@media (min-width: 1000px) {
  .nav {
    width: 300px;
  }
}
@media (min-width: 1400px) {
  .nav {
    width: 350px;
  }
}
@media (min-width: 1800px) {
  .nav {
    width: 400px;
  }
}

.nav-heading {
  margin: 0.75rem 1.3rem;
  align-items: center;
  text-transform: uppercase;
}

.nav-component-active {
  /* min-height: 100%; */
  overflow-y: auto;
}

.nav-component-active .nav-heading-active {
  position: sticky;
  top: 0;
  margin-top: 0;
}

.nav-heading-active {
  position: sticky;
  top: 15px;
}

.nav-component-heading + .nav-list {
  height: 90%;
  margin-bottom: 0;
}

.nav-li {
  /*margin-bottom: var(--guk-space-2); */
  margin-left: 1.3rem;
  line-height: 1.5rem;
}

.nav-icon {
  margin-right: 0.5rem;
  height: 1rem;
  width: 1rem;
}

.close-menu-btn {
  display: flex;
  padding: var(--lg);
  margin-top: calc(-1 * var(--side-margin));
  margin-left: calc(-1 * var(--side-margin));
}
@media (min-width: 1000px) {
  .close-menu-btn {
    display: none;
  }
}

@media (max-width: 1000px) {
  .nav {
    flex-grow: 1;
    padding: var(--lg);
  }
  .nav:not(.toggled) {
    display: none;
  }
  .nav.toggled ~ .main {
    display: none;
  }
}
button.collapse-toggle {
  margin-left: -1.3rem;
  width: 1rem;
  height: 1rem;
}

/*
.collapsible-content {
  margin-bottom: var(--md);
}*/
:root {
  --side-margin: var(--xl);
}
@media (max-width: 1000px) {
  :root {
    --side-margin: var(--lg);
  }
}

.main {
  min-width: 0; /* This is necessary to avoid overflow due to <pre> children. */
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.navbar, .article-wrapper {
  margin: 0 var(--side-margin);
  max-width: 1280px;
}

.navbar {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.navbar-burger {
  margin-left: calc(-1 * var(--side-margin));
  margin-right: 0.5em;
  padding: var(--lg);
  display: flex;
}

.navbar-logo {
  flex-grow: 99;
  display: flex;
  align-items: stretch;
}
.navbar-logo a {
  padding: var(--lg) 0;
  display: flex;
  align-items: center;
}

.navbar-search {
  position: relative;
  display: flex;
  flex-grow: 1;
}

.navbar-search-input {
  flex-grow: 1;
  width: 20em;
  padding: 0.5rem !important;
}

.navbar-end {
  /* flex-grow: 1; */
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.navbar-item {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1.5;
  margin-right: 0.25em;
  margin-left: 0.25em;
}

.article-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.article {
  min-width: 0; /* This is necessary to avoid overflow due to <pre> children. */
  flex-grow: 1;
}

.toc-sidebar {
  margin-left: var(--lg);
  flex-grow: 0;
  flex-shrink: 0;
  max-height: 80vh;
  position: sticky;
  top: 10vh;
  overflow-y: auto;
  padding: 1rem;
  padding-left: 0;
  width: 200px;
}
@media (min-width: 1000px) {
  .toc-sidebar {
    width: 250px;
  }
}
@media (min-width: 1500px) {
  .toc-sidebar {
    width: 300px;
  }
}
@media (min-width: 1800px) {
  .toc-sidebar {
    width: 350px;
  }
}
@media (max-width: 666.6666666667px) {
  .toc-sidebar {
    display: none;
  }
}

.notoc .toc-sidebar {
  display: none;
}

/* To reset HubSpot Popup Banner's default animation effect */
body .leadinModal.leadinModal-theme-top {
  top: auto;
  bottom: 0;
  z-index: 99999999999;
}

.leadinModal.leadinModal-theme-top .leadinModal-content {
  animation-name: leadinModal-reset !important;
}

.leadinModal.leadinModal-theme-top.leadinModal-closing,
.leadinModal.leadinModal-theme-top.leadinModal-closing .leadinModal-content {
  animation-name: leadinModal-fadeout !important;
}

@keyframes leadinModal-reset {
  0% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    opacity: 0;
  }
  1% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    opacity: 0;
  }
  2% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    opacity: 1;
  }
}
/* header.scss */
.header {
  border-bottom: solid 1px var(--guk-color-neutral-200); /* Replaces #e2e2e7 */
}

.navbar {
  /* background-color: var(--guk-color-neutral-0); /* Assuming white background */
  color: var(--guk-color-neutral-900); /* Replaces var(--color-navbar-text) */
  font-size: var(--guk-font-size-sm); /* 0.875rem */
  word-wrap: break-word;
  z-index: 10; /* Ensure this z-index is appropriate */
}

.navbar a {
  text-decoration: none;
}

.navbar-brand .navbar-item:first-child,
.navbar-brand .navbar-item:first-child a {
  color: var(--guk-color-neutral-900); /* Replaces var(--color-navbar-text) */
  font-size: var(--guk-font-size-lg); /* 1.125rem */
}

.navbar-brand .separator {
  padding: 0 var(--guk-space-1); /* 4px */
}

.navbar-burger {
  color: var(--guk-color-neutral-900); /* Replaces var(--color-navbar-text) */
  background: none;
  border: none;
  outline: none;
}

.navbar-burger::-moz-focus-inner {
  border: none;
}

.navbar-item {
  margin-left: 32px;
  margin-right: 0px;
  color: var(--guk-color-neutral-500); /* Replaces var(--color-text) */
  padding: var(--guk-space-2); /* 8px */
  font-family: var(--guk-font-family); /* Replaces var(--heading) */
  font-weight: var(--guk-font-weight-medium); /* Replaces font-weight: 500 */
  font-size: var(--guk-font-size-base); /* 1rem */
}

.navbar-item:hover {
  color: var(--guk-color-neutral-800); /* Replaces var(--color-navbar-hover) */
}

.navbar-button {
  color: var(--guk-color-neutral-0); /* White text */
  padding: var(--guk-space-1-5) var(--guk-space-3); /* 6px 12px */
  font-family: var(--guk-font-family); /* Replaces var(--heading) */
  font-weight: var(--guk-font-weight-medium); /* Replaces font-weight: 500 */
  font-size: var(--guk-font-size-base); /* 1rem */
  display: inline-block;
  border-radius: var(--guk-border-rounded-full); /* Fully rounded button */
  letter-spacing: -0.02em;
  transition: all 0.2s ease;
  background: var(--guk-color-primary-500); /* Replaces #4F56FA */
  text-decoration: none;
  text-transform: none;
  border: 1px solid var(--guk-color-neutral-200); /* Replaces #EFEFEF */
  text-align: center;
  white-space: nowrap;
}

.navbar-button:hover {
  background: var(--guk-color-primary-600) !important; /* Replaces #4349D4 */
  color: var(--guk-color-neutral-0);
}

.navbar-search-input {
  font-size: var(--guk-font-size-base); /* 1rem */
  background-color: var(--guk-color-neutral-50); /* Replaces var(--aluminum-1) */
  color: var(--guk-color-neutral-500);
  border-radius: var(--guk-border-rounded);
  border: 1px solid var(--guk-color-neutral-200); /* Replaces var(--aluminum-3) */
}
.navbar-search-input:focus {
  outline: none;
}
.navbar-search-input:before {
  content: url(../images/icons/arrow_back.svg);
  display: inline-block;
}

.navbar-search-checkbox {
  display: none;
}

@media screen and (min-width: 1000px) {
  .navbar-burger {
    display: none;
  }
}
/* footer.scss */
footer.footer {
  font-size: var(--guk-size-base);
  background-color: var(--guk-color-neutral-0); /* #ffffff */
  padding: var(--guk-space-6) var(--guk-space-8); /* 24px 32px */
  color: var(--guk-color-neutral-500); /* #616e7c */
  border-top: solid 1px var(--guk-color-neutral-100); /* Uses --guk-color-neutral-900 */
}

.footer a {
  color: var(--guk-color-neutral-500); /* Semi-transparent using --guk-color-neutral-900 */
  display: inline;
  margin-left: var(--guk-space-4); /* 6px */
  /* border-left: 1px solid rgba(31, 41, 51, 0.7); */
  padding-left: var(--guk-space-1-5); /* 6px */
}

.footer a:hover {
  color: var(--guk-color-neutral-800); /* Semi-transparent using --guk-color-neutral-900 */
}

/*!
  Customized Theme aligned with the Style Guide
*/
/* Dark Theme */
.hljs {
  color: var(--guk-color-neutral-300); /* Default text color */
  background: var(--guk-color-neutral-900); /* Background color */
}

.hljs-doctag,
.hljs-keyword,
.hljs-meta .hljs-keyword,
.hljs-template-tag,
.hljs-template-variable,
.hljs-type,
.hljs-variable.language_ {
  /* Keywords */
  color: var(--guk-color-critical-500);
}

.hljs-title,
.hljs-title.class_,
.hljs-title.class_.inherited__,
.hljs-title.function_ {
  /* Titles and Functions */
  color: var(--guk-color-primary-300);
}

.hljs-attr,
.hljs-attribute,
.hljs-literal,
.hljs-meta,
.hljs-number,
.hljs-operator,
.hljs-variable,
.hljs-selector-attr,
.hljs-selector-class,
.hljs-selector-id {
  /* Attributes, Numbers, Operators */
  color: var(--guk-color-info-300);
}

.hljs-regexp,
.hljs-string,
.hljs-meta .hljs-string {
  /* Strings and Regex */
  color: var(--guk-color-info-500);
}

.hljs-built_in,
.hljs-symbol {
  /* Built-in and Symbols */
  color: var(--guk-color-warning-500);
}

.hljs-comment,
.hljs-code,
.hljs-formula {
  /* Comments */
  color: var(--guk-color-neutral-500);
}

.hljs-name,
.hljs-quote,
.hljs-selector-tag,
.hljs-selector-pseudo {
  /* Names and Selectors */
  color: var(--guk-color-success-500);
}

.hljs-subst {
  /* Substitutions */
  color: var(--guk-color-neutral-300);
}

.hljs-section {
  /* Sections */
  color: var(--guk-color-primary-500);
  font-weight: bold;
}

.hljs-bullet {
  /* Bullets */
  color: var(--guk-color-warning-300);
}

.hljs-emphasis {
  /* Emphasis */
  color: var(--guk-color-neutral-300);
  font-style: italic;
}

.hljs-strong {
  /* Strong */
  color: var(--guk-color-neutral-300);
  font-weight: bold;
}

.hljs-addition {
  /* Additions */
  color: var(--guk-color-success-300);
  background-color: var(--guk-color-success-100);
}

.hljs-deletion {
  /* Deletions */
  color: var(--guk-color-critical-300);
  background-color: var(--guk-color-critical-100);
}

.hljs-char.escape_,
.hljs-link,
.hljs-params,
.hljs-property,
.hljs-punctuation,
.hljs-tag {
  color: inherit;
  background-color: transparent;
}

/* Light Theme */
.hljs-theme-light .hljs {
  color: var(--guk-color-neutral-900);
  background: var(--guk-color-neutral-0);
}
.hljs-theme-light .hljs-doctag,
.hljs-theme-light .hljs-keyword,
.hljs-theme-light .hljs-meta .hljs-keyword,
.hljs-theme-light .hljs-template-tag,
.hljs-theme-light .hljs-template-variable,
.hljs-theme-light .hljs-type,
.hljs-theme-light .hljs-variable.language_ {
  color: var(--guk-color-critical-500);
}
.hljs-theme-light .hljs-title,
.hljs-theme-light .hljs-title.class_,
.hljs-theme-light .hljs-title.class_.inherited__,
.hljs-theme-light .hljs-title.function_ {
  color: var(--guk-color-primary-500);
}
.hljs-theme-light .hljs-attr,
.hljs-theme-light .hljs-attribute,
.hljs-theme-light .hljs-literal,
.hljs-theme-light .hljs-meta,
.hljs-theme-light .hljs-number,
.hljs-theme-light .hljs-operator,
.hljs-theme-light .hljs-variable,
.hljs-theme-light .hljs-selector-attr,
.hljs-theme-light .hljs-selector-class,
.hljs-theme-light .hljs-selector-id {
  color: var(--guk-color-primary-500);
}
.hljs-theme-light .hljs-regexp,
.hljs-theme-light .hljs-string,
.hljs-theme-light .hljs-meta .hljs-string {
  color: var(--guk-color-primary-700);
}
.hljs-theme-light .hljs-built_in,
.hljs-theme-light .hljs-symbol {
  color: var(--guk-color-warning-500);
}
.hljs-theme-light .hljs-comment,
.hljs-theme-light .hljs-code,
.hljs-theme-light .hljs-formula {
  color: var(--guk-color-neutral-500);
}
.hljs-theme-light .hljs-name,
.hljs-theme-light .hljs-quote,
.hljs-theme-light .hljs-selector-tag,
.hljs-theme-light .hljs-selector-pseudo {
  color: var(--guk-color-success-500);
}
.hljs-theme-light .hljs-subst {
  color: var(--guk-color-neutral-900);
}
.hljs-theme-light .hljs-section {
  color: var(--guk-color-primary-500);
  font-weight: bold;
}
.hljs-theme-light .hljs-bullet {
  color: var(--guk-color-warning-300);
}
.hljs-theme-light .hljs-emphasis {
  color: var(--guk-color-neutral-900);
  font-style: italic;
}
.hljs-theme-light .hljs-strong {
  color: var(--guk-color-neutral-900);
  font-weight: bold;
}
.hljs-theme-light .hljs-addition {
  color: var(--guk-color-success-700);
  background-color: var(--guk-color-success-100);
}
.hljs-theme-light .hljs-deletion {
  color: var(--guk-color-critical-700);
  background-color: var(--guk-color-critical-100);
}
.hljs-theme-light .hljs-char.escape_,
.hljs-theme-light .hljs-link,
.hljs-theme-light .hljs-params,
.hljs-theme-light .hljs-property,
.hljs-theme-light .hljs-punctuation,
.hljs-theme-light .hljs-tag {
  color: inherit;
  background-color: transparent;
}

.article {
  padding-bottom: var(--guk-space-5); /* matches nav padding */
  max-width: 900px;
  margin: 0 auto;
  padding: 40px;
}
.article .listingblock {
  position: relative;
}
.article .listingblock .btn-copy {
  visibility: hidden;
  position: absolute;
  top: 0;
  right: 0;
  border-radius: var(--guk-border-rounded);
}
.article .listingblock:hover .btn-copy {
  visibility: visible;
}
.article .listingblock.nopadding pre code {
  padding: 0;
}
.article .listingblock.nopadding .btn-copy {
  padding: 0;
}
.article pre code {
  display: block;
  overflow-x: auto;
  padding: var(--guk-space-4);
  border-radius: var(--guk-border-rounded);
  max-width: 90vw;
}
.article img {
  max-width: 100%;
  height: auto;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.article aside {
  background: var(--guk-color-neutral-50);
  border: 1px solid var(--guk-color-neutral-200);
  border-radius: var(--guk-border-rounded);
  color: var(--guk-color-neutral-700);
  font-size: var(--guk-font-size-sm); /* 14px */
  padding: var(--guk-space-3) var(--guk-space-5) var(--guk-space-2);
}
.article aside::before {
  background-image: url(../images/icons/note.svg);
  background-position: left 40%;
  background-repeat: no-repeat;
  background-size: 18px;
  color: var(--guk-color-neutral-900);
  content: "Note";
  display: block;
  font-size: var(--guk-font-size-base); /* 16px */
  font-weight: var(--guk-font-weight-semibold);
  opacity: 0.7;
  padding-left: var(--guk-space-5);
}
.article code.primary {
  color: var(--guk-color-neutral-800);
}
.article code.secondary {
  color: var(--guk-color-primary-400);
}
.article code.light {
  color: var(--guk-color-neutral-700);
}

.heading-link,
.anchor {
  float: right;
  visibility: hidden;
  color: inherit;
  font-size: 0.8em;
  margin-top: calc(var(--guk-space-base) * 0.25); /* Equivalent to 1px */
  margin-left: calc(var(--guk-space-base) * 0.25);
}
.heading-link:focus, h1:hover .heading-link, h2:hover .heading-link, h3:hover .heading-link, h4:hover .heading-link, h5:hover .heading-link, h6:hover .heading-link,
.anchor:focus,
h1:hover .anchor,
h2:hover .anchor,
h3:hover .anchor,
h4:hover .anchor,
h5:hover .anchor,
h6:hover .anchor {
  visibility: visible;
}
.contract .heading-link,
.contract .anchor {
  visibility: visible;
}

.anchor {
  padding: 0 calc(var(--guk-space-base) * 0.75); /* Approximately 3px */
  text-decoration: none;
}
.anchor::before {
  content: "#";
}

.contract h3 code {
  padding: 0;
  color: inherit;
  background-color: transparent;
}

.contract-index {
  border-radius: var(--guk-border-rounded);
  max-width: 90vw;
  padding: var(--guk-space-6);
  background-color: #f8f8fa;
  margin-top: var(--guk-space-4);
  margin-bottom: var(--guk-space-4);
  word-wrap: break-word;
  color: var(--color-text);
}
.contract-index ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.contract-index ul a {
  color: inherit;
  text-decoration: none;
}
.contract-index ul a:hover {
  text-decoration: underline;
}
.contract-index ul a pre,
.contract-index ul a code {
  padding: 0;
  color: inherit;
  line-height: 1rem;
  background-color: transparent;
}
.contract-index .title {
  font-family: var(--guk-font-family);
  font-size: var(--guk-font-size-sm); /* Approximately 0.9em */
  color: var(--guk-color-primary-400);
  letter-spacing: 0.05em;
  font-weight: var(--guk-font-weight-semibold);
  text-transform: uppercase;
}
.contract-index .ulist {
  border-top: solid 1px var(--guk-color-neutral-300);
  margin-top: var(--guk-space-4);
  padding-top: calc(var(--guk-space-base) * 0.25);
}
.contract-index .ulist > .title {
  float: right;
  color: var(--guk-color-neutral-900);
  margin-top: calc(var(--guk-space-base) * 2);
  font-size: var(--guk-font-size-xs);
}

.contract-subindex-inherited {
  opacity: 0.7;
}

.contract-item {
  border-radius: var(--border-radius);
  border: solid 1px #ddd;
  max-width: 90vw;
  padding: 0;
  margin-top: 1em;
  margin-bottom: 1em;
  word-wrap: break-word;
}
.contract-item > * {
  margin-right: 1em;
  margin-left: 1em;
}
.contract-item h4 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-start;
  padding: 0;
  margin: 1em;
  margin-bottom: 2em;
  position: relative;
  font-size: inherit;
}
.contract-item h4 code {
  flex-grow: 1;
  color: inherit;
  font-weight: var(--weight-normal);
  background-color: transparent;
  padding: 0;
}
.contract-item h4 code .contract-item-name {
  font-weight: var(--weight-medium);
}
.contract-item h4 .anchor {
  order: 10;
  font-size: var(--md);
  font-weight: normal;
  margin-top: 0;
}
.contract-item h4 .item-kind {
  font-family: var(--sans-serif);
  font-size: var(--md);
  font-weight: 300;
  opacity: 0.8;
}
.contract-item h4::before {
  content: "";
  display: block;
  position: absolute;
  height: 100%;
  width: 100%;
  box-sizing: content-box;
  padding: 1em;
  margin: -1em;
  z-index: -10;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  background-color: #f9f9fa;
  border-bottom: solid 1px #ddd;
}
.contract-item .admonitionblock {
  font-family: var(--sans-serif);
  margin-right: 1em;
  margin-left: 1em;
}

.article-navigation {
  display: flex;
}
.article-navigation div {
  display: block;
  flex-grow: 1;
  font-weight: var(--guk-font-weight-normal);
  margin-top: var(--guk-space-6);
  margin-bottom: var(--guk-space-2);
}
.article-navigation div a {
  text-decoration: none;
  font-size: var(--guk-font-size-base);
  color: var(--guk-color-neutral-500);
}
.article-navigation div a:hover {
  color: var(--guk-color-primary-400);
}
.article-navigation div.btn-next {
  text-align: right;
}

.article-banner {
  background-color: var(--guk-color-neutral-100);
  border: solid 1px var(--guk-color-neutral-300);
  border-radius: var(--guk-border-rounded);
  padding: var(--guk-space-2-5);
  margin: 0 calc(-1 * var(--guk-space-2-5));
}

.doc table {
  width: 100%;
  max-width: 100%;
  overflow-x: scroll;
  display: block;
  position: relative;
}
.doc table:hover {
  background: var(--guk-color-neutral-50);
}

/* admonitionblock.scss */
.alert p {
  font-family: var(--guk-font-family);
  color: inherit;
  font-size: var(--guk-font-size-base); /* 1rem */
}

.admonitionblock,
.alert {
  max-width: 90vw;
  background-color: var(--guk-color-neutral-0); /* white */
  border: solid var(--guk-color-neutral-300); /* #9aa5b1 */
  border-radius: var(--guk-border-rounded);
  border-width: 1px 1px 1px 6px;
  font-family: var(--guk-font-family);
  font-weight: var(--guk-font-weight-normal);
  color: var(--guk-color-neutral-600); /* Replaces var(--color-text-light) */
  font-size: var(--guk-font-size-sm); /* 0.875rem */
  text-align: center;
  line-height: var(--guk-line-height-normal);
  padding: var(--guk-space-5) var(--guk-space-4);
  margin-bottom: var(--guk-space-7);
  overflow-wrap: break-word;
}
.admonitionblock table,
.alert table {
  padding: 0;
  margin: 0;
}
.admonitionblock table th,
.admonitionblock table td,
.alert table th,
.alert table td {
  padding: 0;
  margin: 0;
  word-wrap: break-word;
}
.admonitionblock .content,
.alert .content {
  max-width: 82vw;
  padding-left: var(--guk-space-1);
  font-size: var(--guk-font-size-base); /* 1rem */
  text-align: left;
  word-wrap: break-word;
}
.admonitionblock::before,
.alert::before {
  display: block;
  font-size: 0.9em;
  font-weight: var(--guk-font-weight-semibold);
  text-align: left;
  text-transform: uppercase;
  padding-left: var(--guk-space-1);
  margin-bottom: var(--guk-space-2);
}
.admonitionblock.note,
.alert.note {
  border-left-color: var(--guk-color-primary-400); /* Replaces var(--core-violet-4) */
}
.admonitionblock.note::before,
.alert.note::before {
  content: "Note";
  color: var(--guk-color-primary-400);
}
.admonitionblock.tip,
.alert.tip {
  border-left-color: var(--guk-color-info-500); /* Replaces var(--teal-3) */
}
.admonitionblock.tip::before,
.alert.tip::before {
  content: "Tip";
  color: var(--guk-color-info-500);
}
.admonitionblock.warning, .admonitionblock.caution, .admonitionblock.important,
.alert.warning,
.alert.caution,
.alert.important {
  border-left-color: var(--guk-color-warning-500); /* Replaces #F79F1F */
}
.admonitionblock.warning::before, .admonitionblock.caution::before, .admonitionblock.important::before,
.alert.warning::before,
.alert.caution::before,
.alert.important::before {
  color: var(--guk-color-warning-500);
  padding-left: var(--guk-space-6); /* Adjusted for spacing */
  margin-left: var(--guk-space-2);
  background-image: url(../images/icons/warning.svg);
  background-repeat: no-repeat;
  background-position: left;
  background-origin: border-box;
}
.admonitionblock.warning::before,
.alert.warning::before {
  content: "Warning";
}
.admonitionblock.caution::before,
.alert.caution::before {
  content: "Caution";
}
.admonitionblock.important::before,
.alert.important::before {
  content: "Important";
}

.toc-sidebar {
  font-size: 0.8em;
  opacity: 0.8;
}

.toc-menu {
  border-width: 0 0 0 0.25rem;
}
.toc-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 1.2;
}
.toc-menu .subheading-list {
  border-left: solid 1px var(--guk-color-neutral-200);
  margin-left: var(--guk-space-2); /* Equivalent to 8px */
}

.toc-title {
  font-size: var(--guk-font-size-sm);
  margin-bottom: 16px;
  padding: 0;
  font-family: var(--guk-font-family); /* Replaces var(--heading) */
  color: var(--guk-color-neutral-400); /* Replaces var(--aluminum-5) */
  letter-spacing: 0.05em;
  font-weight: var(--guk-font-weight-normal); /* Assuming bold weight */
  text-transform: uppercase;
}

.toc .toc-menu li {
  margin: 0.5rem 0 0;
  padding-top: var(--guk-space-1); /* 4px */
  padding-bottom: var(--guk-space-1);
  padding-right: var(--guk-space-3); /* 8px */
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: var(--guk-font-weight-normal);
  font-family: var(--guk-font-family); /* Replaces var(--sans-serif) */
  font-size: var(--guk-font-size-base); /* 1rem */
}
.toc .toc-menu li.subheading-item {
  font-weight: var(--guk-font-weight-normall); /* Replaces var(--weight-normal) */
  /* margin: 0.25rem 0 0; */
  margin-left: 0.7rem;
}

.toc a {
  color: inherit;
  text-decoration: none;
  padding-right: 8px;
}
.toc a:hover {
  color: var(--guk-color-primary-300); /* Replaces var(--core-violet-2) */
}

.toc .toc-menu li a.toc-active {
  color: var(--guk-color-primary-400); /* Replaces var(--core-violet-4) */
}

.hardhat-truffle-toggle {
  width: 120px;
  height: 30px;
  border: 0;
  outline: 0;
  padding: 0;
  margin: 0;
  background: url(../images/toggle-active.svg) center/contain no-repeat;
  overflow: hidden;
  white-space: nowrap;
}
.hardhat-truffle-toggle:active {
  background-image: url(../images/toggle-active.svg) !important;
}
.hardhat-truffle-toggle span {
  pointer-events: none;
  opacity: 0;
}

.preference-truffle .hardhat {
  display: none;
}
.preference-truffle .hardhat-truffle-toggle {
  background-image: url(../images/toggle-preference-truffle.svg);
}

.preference-hardhat .truffle {
  display: none;
}
.preference-hardhat .hardhat-truffle-toggle {
  background-image: url(../images/toggle-preference-hardhat.svg);
}

/* hubspot banner styles */
/* !important is essential in this context to take precedent over default styles */
div#hs-banner-parent div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner {
  padding: var(--u2) !important;
}

div#hs-banner-parent div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner div#hs-eu-policy-wording {
  margin-right: 0 !important;
  margin-bottom: var(--u) !important;
}

div#hs-banner-parent div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner div#hs-eu-policy-wording p,
div#hs-banner-parent div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner p#hs-eu-cookie-disclaimer {
  line-height: 16px !important;
  max-width: 100% !important;
  margin: 0 !important;
}

div#hs-banner-parent div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner div#hs-eu-cookie-confirmation-buttons-area {
  margin-top: var(--u) !important;
  margin-right: 0 !important;
}

div#hs-banner-parent div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner button#hs-eu-confirmation-button,
div#hs-banner-parent div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner button#hs-eu-decline-button {
  height: 30px !important;
}

@media (min-width: 751px) {
  div#hs-banner-parent div#hs-eu-cookie-confirmation div#hs-eu-cookie-confirmation-inner p#hs-eu-cookie-disclaimer {
    margin-right: 0 !important;
  }
}