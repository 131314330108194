/* typography.scss */

/* Font-face Definitions */
@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope-Regular.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Manrope";
  src: url("../fonts/Manrope-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

/* Body Styles */
body {
  color: var(--guk-color-neutral-900);
  font-family: var(--guk-font-family);
  font-size: var(--guk-font-size-base); /* 1rem */
  line-height: var(--guk-line-height-normal); /* 1.5 */
}

/* Headings */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--guk-color-neutral-900);
  font-family: var(--guk-font-family);
 /* font-weight: var(--guk-font-weight-semibold); /* 600 */
  line-height: var(--guk-line-height-tight); /* 1.25 */
  margin-bottom: var(--guk-space-4);
  margin-top: var(--guk-space-7);
}

/*
h2,
h3,
h4,
h5,
h6 {
  padding-top: var(--guk-space-8); 
  
}
*/

h1
 {
 /* padding-top: var(--guk-space-6); /* Adjusted spacing */
  border-bottom: 1px solid var(--guk-color-neutral-200);
}

/* Heading Specific Styles */
h1 {
  font-size: var(--guk-font-size-3xl); /* 3rem */
  padding-bottom: var(--guk-space-2);
}

h2 {
  font-size: var(--guk-font-size-2xl); /* 2.25rem */
}

h3 {
  font-size: var(--guk-font-size-xl); /* 1.875rem */
}

h4 {
  font-size: var(--guk-font-size-lg); /* 1.5rem */
}

h5 {
  font-size: var(--guk-font-size-m); /* 1.25rem */
}

h6 {
  font-size: var(--guk-font-size-m); /* 1.125rem */
}

/* Inline Code */
code {
  background-color: var(--guk-color-neutral-50); /* #f5f7fa */
  border-radius: var(--guk-border-rounded); /* 4px */
  font-family: var(--guk-font-family-monospace, monospace);
  font-size: 0.95em;
  padding: 0;
  border: 1px solid var(--guk-color-neutral-200);
}

/* Paragraphs and Lists */
p,
figure {
  margin-bottom: var(--guk-space-4); 
  /* margin-top: var(--guk-space-6); */
}

ul,
ol,
dl {
  margin-bottom: var(--guk-space-4);
  margin-top: var(--guk-space-4);
  padding-left: var(--guk-space-6);
}

li {
  margin-top: var(--guk-space-2);
  margin-bottom: var(--guk-space-2);
}

ol {
  list-style-type: decimal;
}

ul {
  list-style-type: disc;
}

/* Links */
a {
  color: var(--guk-color-primary-500);
  text-decoration: none;
  transition: color var(--transition-speed-md) var(--transition-timing);

  &:hover {
    color: var(--guk-color-primary-400);
  }

}

/* Preformatted Text */
pre,
code {
  font-family: var(--guk-font-family-monospace, monospace);
  font-size: 0.95em;
}

pre {
  background-color: var(--guk-color-neutral-0);
  border-radius: var(--guk-border-rounded);
/*  padding: var(--guk-space-4); */
  overflow: auto;
  margin-bottom: var(--guk-space-6);
  margin-top: var(--guk-space-6);
}

/* Table Headings */
thead {
  font-family: var(--guk-font-family);
  font-variant: small-caps;
  text-transform: lowercase;
  letter-spacing: 0.05em;
  color: var(--guk-color-neutral-500);
  border-bottom: 1px solid var(--guk-color-neutral-200);
}

th {
  text-align: left;
  padding: var(--guk-space-2);
}

td,
th {
  padding: var(--guk-space-2);
}

td > .tableblock {
  margin: 0;
}

/* Definition Lists */
dl {
  border: 1px solid var(--guk-color-neutral-200);
  border-radius: var(--guk-border-rounded);
  display: inline-block;
  padding: var(--guk-space-4);
}

dt {
  font-weight: var(--guk-font-weight-semibold);

  &::after {
    content: ":";
  }
}

dd {
  margin-left: 0;

  + dt {
    border-top: 1px solid var(--guk-color-neutral-200);
    margin-top: var(--guk-space-2);
    padding-top: var(--guk-space-2);
  }
}

/* Blockquotes */
blockquote {
  margin-left: var(--guk-space-8);
  border-left: 4px solid var(--guk-color-neutral-200);
  padding-left: var(--guk-space-4);
  color: var(--guk-color-neutral-700);
  font-style: italic;
}

blockquote + .attribution {
  text-align: right;
  font-size: var(--guk-font-size-sm);
  color: var(--guk-color-neutral-600);
}

/* Strong Text */
strong,
.strong {
  font-weight: var(--guk-font-weight-semibold);
}

/* Strikethrough Text */
.strikethrough {
  text-decoration: line-through;
}

/* Miscellaneous */
pre,
table,
aside {
  margin-bottom: var(--guk-space-6);
  margin-top: var(--guk-space-6);
}

/* Variables Fallbacks */
:root {
  --guk-font-family-monospace: "Roboto Mono", monospace;
}

.info-text-style {
    margin-top: var(--guk-space-14);
}
